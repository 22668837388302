<template>
  <div class="container-fluid">
    <div class="row">
      <b-col sm="3">
        <SidePage></SidePage>
      </b-col>
      <b-col sm="9">
        <b-container>
          <b-row>
            <div class="ProdList container my-3">
              <b-skeleton-wrapper :loading="popularLayoutLoading">
                <template #loading>
                  <b-container fluid>
                    <landingLoader></landingLoader>
                  </b-container>
                </template>
                <b-row v-if="!popularLayoutLoading">
                  <b-col
                    sm="3"
                    cols="6"
                    class="px-1 my-1"
                    v-for="Product in PopularLayout.tag.items.data"
                    v-bind:key="Product.id"
                  >
                    <ProdCard
                      class="ProdByCatItem"
                      v-bind:Product="Product"
                    ></ProdCard>
                  </b-col>
                </b-row>
                <b-row>
                  <pagination
                    v-if="!popularLayoutLoading"
                    :data="PopularLayout.tag.items"
                    @pagination-change-page="getResults"
                    :limit="4"
                    class="mx-auto my-3 flex-wrap"
                  ></pagination>
                </b-row>
              </b-skeleton-wrapper>
            </div>
          </b-row>
        </b-container>
      </b-col>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SidePage from "../components/widgets/SidePage.vue";
import ProdCard from "../components/items/ProdCard.vue";
import LaravelVuePagination from "laravel-vue-pagination";
import landingLoader from "../components/widgets/landingLoader.vue";

export default {
  metaInfo: {
    title: "Popular",
        meta: [
      {
        vmid: "description",
        name: "description",
        content:"تعرف على المنتجات الأكثر طلبًا من عملائنا في المملكة العربية السعودية والإمارات العربية المتحدة ومصر ودول أخرى"      },
    ],
  },
  components: {
    SidePage,
    ProdCard,
    landingLoader,
    pagination: LaravelVuePagination,
  },
  computed: {
    ...mapGetters(["Categories", "PopularLayout"]),
  },
  data() {
    return {
      popularLayoutLoading: true,
    };
  },
  methods: {
    ...mapActions(["getProdByTag"]),
    getResults(page = 1) {
      var obj = {
        tag: "Trending",
        breakpoint: this.$mq,
        Where: "popular",
        page: page,
      };
      //this.getLanding(obj);
      this.getProdByTag(obj);
    },
  },
  mounted() {
    this.getResults();
  },
  watch: {
    PopularLayout(newValue) {
      if (newValue.tag) {
        this.popularLayoutLoading = false;
      }
    },
  },
};
</script>

<style>
</style>